import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import './styles.scss'

const SocialLinks = ({ className }) => {
    const data = useStaticQuery(graphql`
        {
            wp {
                seo {
                    social {
                        facebook {
                            url
                        }
                        instagram {
                            url
                        }
                        linkedIn {
                            url
                        }
                        twitter {
                            username
                        }
                        youTube {
                            url
                        }
                    }
                }
            }
        }
    `)

    const socialLinks = data.wp.seo.social

    return (
        <div className={`c-social-links ${className ? className : ''}`}>
            <a href={socialLinks.facebook.url}>
                <i className="icon-facebook"></i>
                <span className="sr-only">Facebook</span>
            </a>
            <a href={`https://twitter.com/${socialLinks.twitter.username}`}>
                <i className="icon-twitter"></i>
                <span className="sr-only">Twitter</span>
            </a>
            <a href={socialLinks.instagram.url}>
                <i className="icon-instagram"></i>
                <span className="sr-only">Instagram</span>
            </a>
            <a href={socialLinks.youTube.url}>
                <i className="icon-youtube"></i>
                <span className="sr-only">Youtube</span>
            </a>
        </div>
    )
}

SocialLinks.propTypes = {
    /**
     * Optional CSS classes
     */
    className: PropTypes.string
}

export default SocialLinks
